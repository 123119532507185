
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";

interface InvoiceBasisDto {
  invoiceBasisNumber: number;
  inserted: string;
  type: string;
  accountingClient: string;
  accountingDepartment: string;
  courseOrganizer: string;
  InvoiceOuFond: number;
  courseId: number;
  courseName: string;
}

export default defineComponent({
  name: "InvoiceBasisListPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
  },
  setup() {
    const search = ref<string>("");
    const router = useRouter();
    const invoiceBases = ref<InvoiceBasisDto[]>([]);

    const goToInvoiceDetailsPage = (item: InvoiceBasisDto) => {
      // TODO Change to go to invoice basis details
      router.push({
        name: SingleCourseRouteNames.CourseDashboard,
        params: {
          id: item.courseId.toString(),
        },
      });
    };

    const downloadInvoiceBasis = async () => {
      /* globalLoadingWrapper({ blocking: true }, async () => {
        if (!invoiceBasis.invoiceBasisNumber) {
          return;
        }
        const contractFile = (await binaryApi.file.downloadFileAsync(workingHourEntry.fileId, { format: "blob" })).data;
        fileDownload(contractFile, workingHourEntry.fileName);
      }); */
    };

    const loadInvoiceBases = async () => {
      /* await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        invoiceBases.value = (await api.clientaccountrate.getClientAccountInvoiceBases()).data;
      }); */
      invoiceBases.value = mockData;
    };

    onMounted(async () => {
      await loadInvoiceBases();
    });

    return {
      invoiceBases,
      search,
      headers,
      formatLocalizedDateP,
      formatCurrency,
      goToInvoiceDetailsPage,
      downloadInvoiceBasis,
    };
  },
});

const mockData: InvoiceBasisDto[] = [
  {
    invoiceBasisNumber: 5,
    inserted: "2022-11-30T00:00:00+00:00",
    type: "Delgrunnlag",
    accountingClient: "Fellesforbundet",
    accountingDepartment: "AOF Norge",
    courseOrganizer: "AOF Norge",
    InvoiceOuFond: 5000,
    courseId: 371,
    courseName: "Kurs for langtlevende mennesker",
  },
  {
    invoiceBasisNumber: 8,
    inserted: "2022-10-19T00:00:00+00:00",
    type: "Hovedgrunnlag",
    accountingClient: "Fellesrådet",
    accountingDepartment: "AOF Norge",
    courseOrganizer: "AOF Norge",
    InvoiceOuFond: 11053,
    courseId: 373,
    courseName: "Kurs i å lage kvad og dikt",
  },
];

const headers = [
  { text: "Grunnlagsnummer", value: "invoiceBasisNumber" },
  { text: "Handlinger", value: "actions", sortable: false },
  { text: "Grunnlagsdato", value: "inserted" },
  { text: "Type grunnlag", value: "type" },
  { text: "Regnskapsklient", value: "accountingClient" },
  { text: "Regnskapsavdeling", value: "accountingDepartment" },
  { text: "Arrangør", value: "courseOrganizer" },
  { text: "Fakturagrunnlag", value: "InvoiceOuFond" },
  { text: "Kurs", value: "courseId" },
];
