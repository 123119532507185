var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "py-1"
        }, [_vm._v("mdi-receipt-text-outline")]), _vm._v(" Fakturagrunnlag ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.invoiceBases,
      "search": _vm.search,
      "sort-by": "inserted",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.inserted",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDateP(value)) + " ")];
      }
    }, {
      key: "item.InvoiceOuFond",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatCurrency(value) : "-") + " ")];
      }
    }, {
      key: "item.courseId",
      fn: function fn(_ref3) {
        var _ref3$item = _ref3.item,
            courseId = _ref3$item.courseId,
            courseName = _ref3$item.courseName;
        return [_vm._v(" " + _vm._s("".concat(courseId, " - ").concat(courseName)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "goToInvoiceDetailsPage"
                },
                on: {
                  "click": function click($event) {
                    return _vm.goToInvoiceDetailsPage(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Åpne fakturagrunnlag")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "downloadInvoiceBasisButton"
                },
                on: {
                  "click": function click($event) {
                    return _vm.downloadInvoiceBasis();
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-file-download-outline")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Last ned fakturagrunnlag")])])];
      }
    }], null, true)
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }